@import '../../../public/scss/variable.scss';

.document-center-table thead  {
    background: $thead;
}

.document-center-table thead th {
    color: $white !important;
    text-transform: uppercase;
    font-size: 16px;
}

.document-center-table tbody td {
    text-transform: uppercase;
    font-size: 16px;
}