.note {
    border-bottom: 1px solid #D3D5D9;
    border-top: 1px solid #D3D5D9;
    padding-bottom: 1rem; 
    padding-top: 1rem; 
}

.note .title{
    font-weight: bold;
}

.note .value{
    padding-left: 0.4rem;
}