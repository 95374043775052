/*Text Colors*/
$bg: #F3F3F3;
$sectionBg: #f3f3f3;
$black: #192534;
$link: #64a4ae;
$dark: #18181d;
$white: #ffffff;
$gray: #A1A6AE;
$txtgray: #878A8C;
$lightGray: #eee;
$btnGray: #ccc;
$red: #DC3545;
$green: #28A745;
$orange: #fb720d;
$nav: #3d699f;
$btnbg: #4F5B6B;
$btnUpload: #67819F;
$border: #B9BEC4;
$btnLight: #72A0A7;
$btndark: #27374B;
$stickyBg: #192534;
$navLink: #063a5a;
$labelTxt: #556f7b;
$labelBg: #003556;
$fontColor: #212529;
$headingColor: #192534;
$headerbg: #77A2A7;
$inputBg: #6DACB5;
$inputBorder: #B9BEC4;
$innerHeader: #9CBCC1;
$linkcolor: #2E62A3;
$thead: #43566C;
$tdColor: #878A8C;

$boxshadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

$normal: normal;
$semiBold: 600;
$bold: bold;
$regular: 300;
$light: 200;

.wd-40 {
	width: 40% !important;
}

/*Fonts*/
$bodyFont: Seravek;
$headingFont: arno-pro-display, Times New Roman, serif;

/*Font Sizes*/
$smallFont: 12px;
$midFont: 14px;

h1 {
	font-size: 22px;
	font-weight: $regular;
	color: $black;
	font-family: $headingFont;
	letter-spacing: 1px;
}

h2,
h3,
h4 {
	font-weight: bold;
	font-family: $headingFont;
	letter-spacing: 1px;
}

.headfont {
	font-family: $headingFont;
	letter-spacing: 0.01em;
}

.font-16 {
	font-size: 16px;
}

.font-20 {
	font-size: 20px;
}

.font-22 {
	font-size: 22px;
}

.font-26 {
	font-size: 26px;
}

/*common classes*/

.semi-bold {
	font-weight: $semiBold;
}

.bold {
	font-weight: $bold;
}

.normal {
	font-weight: normal;
}

.text-center {
	text-align: center;
}

.align-right {
	text-align: right;
}

.align-left {
	text-align: left;
}

.hidden {
	display: none;
}

.error {
	color: $red;
}

.block {
	display: block;
}

.inline {
	display: inline;
}

.rt-1 {
	position: relative;
	top: 1px;
}

sub,
sup {
	top: -1px;
	margin-right: 2px;
}

.grayBg {
	background-color: $lightGray;
}

.gray {
	color: $gray;
}

.vlink {
	color: $linkcolor  !important;
}

.green {
	color: $green;
}

.orange {
	color: $orange;
}

.floatright {
	float: right;
}

.textcenter {
	text-align: center;
}

.link {
	color: $link;
}

.labeltxt {
	color: $labelTxt;
}

.whitebg {
	background: $white;
}

.float-left {
	float: left;
}

.float-right {
	float: right;
}

.border {
	border: 1px solid $border;
}

.border-up {
	border-top: 1px solid $border;
}

.border-rgt {
	border-right: 1px solid $border;
}

.border-lft {
	border-left: 1px solid $border;
}

.border-btm {
	border-bottom: 1px solid $border;
}

.no-padding {
	padding: 0 !important;
}

.no-pad-left {
	padding-left: 0 !important;
}

.no-pad-right {
	padding-right: 0 !important;
}

/*Button styles*/
.button-action {
	position: relative;
	background-color: $btndark;
	color: $white  !important;
	text-align: center;
	text-transform: uppercase;
	border-radius: 3px;
	font-weight: normal;
	font-size: 16px;
	height: 40px;
	line-height: 40px;
	display: block;
	text-decoration: none;
	border: 0;
	width: 100%;
	border-radius: 4px;
	box-shadow: rgba(0, 0, 0, 0.05) 0px 5px 15px;
	cursor: pointer;

	&.button-active {
		background: $btndark;
	}

	&.btn-light {
		background: $btnLight;
		color: $white  !important;
		border: 1px solid $btnLight;

		&:hover {
			background-color: $btnbg;
			color: $white;
		}
	}

	&.btn-upload {
		background-color: $btnUpload;
		color: $white;
	}

	&.btn-gray {
		background: $btnGray;
		color: $black  !important;
		border: 1px solid $btnGray;

		&:hover {
			background-color: $gray;
		}
	}

	&:hover,
	&:focus {
		background: $black;
		color: $white  !important;
	}

	&.btn-disable {
		background: #BCC1C7;
		color: $white  !important;
		cursor: no-drop;
	}

	&.btn-border {
		background-color: $white;
		border: 1px solid $btndark;
		color: $btndark  !important;

		&:hover {
			color: $btndark  !important;
			border: 1px solid rgba(39, 55, 75, .5);
		}
	}

	&.btn-save {
		background-color: #67819F;
	}

	&.btn-error {
		background-color: $red;
	}

	&.btn-success {
		background-color: $green;
	}

	&.btn-auto {
		width: auto;
		display: table;
		margin: 0 auto;
		padding: 0 20px;
	}
}

.btn-link {
	width: 100%;
}