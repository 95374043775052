.LabFile {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 10px;
}

.LabFile span {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    color:blue;
    text-decoration:underline;
}